import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import Portal from 'src/components/common/portal';
import IconSlot from 'src/components/common/icon-slot';
import * as classes from './header.module.scss';
import Logo from './logo';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  function toggleHandler() {
    setIsOpen(prevState => !prevState);
  }
  const data = useStaticQuery(graphql`
    query {
      allPagesYaml {
        edges {
          node {
            slug
            isEnabled
            name
            id
          }
        }
      }
    }
  `);
  const pages = data.allPagesYaml.edges;

  return (
    <>
      <nav className="navbar" style={{ '--py': '16px' }}>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            {/*Logo*/}
            <div>
              {pathname !== '/' && (
                <Link to="/" style={{ color: 'var(--bv-black)' }}>
                  <h1 className="h3">
                    <Logo className="d-block" />
                    <span className="sr-only">BetVentura</span>
                  </h1>
                </Link>
              )}
              {pathname === '/' && (
                <h1 className="h3" style={{ color: 'var(--bv-black)' }}>
                  <Logo className="d-block" isAnimated={false} />
                  <span className="sr-only">BetVentura</span>
                </h1>
              )}
            </div>
            <div className="" style={{ '--m': '-4px -12px' }}>
              {/*Mobile menu toggle button*/}
              <button
                onClick={toggleHandler}
                className={`${classes.toggleButton} d-xxl-none`}
                type="button"
                aria-label="Toggle menu"
              >
                <IconSlot size={34}>
                  <svg
                    className="icon"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    stroke="currentColor"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="3" y1="12" x2="21" y2="12" />
                    <line x1="3" y1="6" x2="21" y2="6" />
                    <line x1="3" y1="18" x2="21" y2="18" />
                  </svg>
                </IconSlot>
              </button>
              <div className={`${classes.nav} ${isOpen ? classes.isOpen : ''} d-xxl-flex align-items-center flex-wrap`}>
                <div className="d-flex justify-content-end" style={{ '--m': '8px 0 4px 0' }}>
                  <button
                    onClick={toggleHandler}
                    className={`${classes.toggleButton} d-xxl-none`}
                    type="button"
                    aria-label="Toggle menu"
                  >
                    <IconSlot size={34}>
                      <svg
                        className="icon"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                      </svg>
                    </IconSlot>
                  </button>
                </div>

                {pages
                  .filter(({ node }) => node.isEnabled)
                  .map(({ node }) => (
                    <div style={{ '--m': '4px 12px' }} key={node.id}>
                      <Link
                        to={`/${node.slug}`}
                        className="d-block"
                        style={{
                          '--color': 'var(--bv-black)',
                          '--color-hover': 'var(--bv-red-100)',
                          '--py': '7px',
                          borderBottom: '3px solid transparent',
                          borderTop: '3px solid transparent',
                        }}
                        activeStyle={{
                          color: 'var(--bv-red-100)',
                          borderBottomColor: 'var(--bv-red-100)',
                          textDecoration: 'none',
                        }}
                      >
                        {node.name}
                      </Link>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/*{isOpen && (*/}
      <Portal>
        <div
          role="button"
          aria-label="Close menu"
          className={`${classes.overlay} ${isOpen ? classes.isOpen : ''} d-xxl-none`}
          onClick={toggleHandler}
          onKeyDown={toggleHandler}
          tabIndex="0"
        />
      </Portal>
      {/*)}*/}
    </>
  );
};

export default Header;
