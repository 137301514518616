import React from 'react';

const Footer = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     allPagesYaml {
  //       edges {
  //         node {
  //           slug
  //           isEnabled
  //           isInFooter
  //           name
  //           id
  //         }
  //       }
  //     }
  //   }
  // `);
  // const pages = data.allPagesYaml.edges;

  const emailClickHandler = (e) => {
    e.preventDefault();
    window.location.href = "mailto:crashwinbet@proton.me";
  };

  return (
    <footer
      className="body-4"
      style={{ background: 'var(--bv-gray-dark-80)', color: 'var(--bv-gray-light-80)', '--py': '40px' }}
    >
      <div className="container">
        <div className="d-flex flex-wrap" style={{ '--m': '-4px' }}>
          <div style={{ '--m': '4px' }}>&copy; {new Date().getFullYear()} BetVentura</div>
          <div style={{ '--m': '4px', opacity: 0.5 }}>|</div>
          <div style={{ '--m': '4px' }}>
            <button
              onClick={emailClickHandler}
              className="cursor-pointer text-decor btn-no-appearance"
              style={{ color: 'var(--bv-gray-light-80)' }}
            >
              feelfree@to.talk
            </button>
          </div>
          {/* <div style={{ '--m': '4px', opacity: 0.5 }}>|</div> */}
          {/* <div style={{ '--m': '4px' }}> */}
            {/* <OutboundLink href="//bitcassinos.com" style={{ color: 'var(--bv-gray-light-80)' }}>
              <IconSlot size={24} className="d-inline-block" style={{ '--me': '4px' }}>
                🇧🇷
              </IconSlot>
              BR
            </OutboundLink> */}
          {/* </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
