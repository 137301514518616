import React from "react";
import ReactDOM from "react-dom/client";
import Layout from "./src/components/common/layout";


export const replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
    }
}


// Replace this by your own page wrapper:
export default function wrapPageElement({ element, props }) {
    return (
        <Layout {...props}>{element}</Layout>
    )
}
