import React, { useEffect, useState } from 'react';

const ScrollTopTopButton = ({ scrollTop = 1000 }) => {
  const [visibleScrollToTop, setVisibleScrollToTop] = useState(false);

  // const scrollToTopHandler = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   });
  // };

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined' && typeof document !== 'undefined';
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > scrollTop) {
        setVisibleScrollToTop(true);
      } else if (scrolled <= scrollTop) {
        setVisibleScrollToTop(false);
      }
    };

    if (isBrowser) {
      window.addEventListener('scroll', toggleVisible);
    }
    return () => {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, [scrollTop]);
  return (
    <>
      {visibleScrollToTop && (
        <a
          href="#page"
          className="p-fixed bottom-right cursor-pointer z-index-fixed"
          style={{
            '--m': '24px',
            // background: 'var(--bv-gray-light-20)',
            // '--p': '4px',
            borderRadius: 100,
            border: 0,
            appearance: 'none',
          }}
        >
          <svg
            className="d-block"
            viewBox="0 0 24 24"
            width="44"
            height="44"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <polyline points="16 12 12 8 8 12" />
            <line x1="12" y1="16" x2="12" y2="8" />
          </svg>
        </a>
      )}
    </>
  );
};

export default ScrollTopTopButton;
