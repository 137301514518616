import React from 'react';

const IconSlot = ({ className = '', size, children, style }) => (
  <div className={`icon-slot ${className}`} style={{ width: size ? `${size}px` : '', ...style }}>
    <div className="p-abs centered" style={{ fontSize: size ? `${size}px` : '' }}>
      {children}
    </div>
  </div>
);

export default IconSlot;
