import * as React from 'react';
import 'src/stylesheets/index.scss';
import { Helmet } from 'react-helmet';
import Header from 'src/components/common/header';
import Footer from 'src/components/common/footer';
import { useSiteMetadata } from 'src/hooks/use-site-metadata';
import ScrollTopTopButton from 'src/components/common/scroll-top-top-button';
import { useEffect } from 'react';
import OneSignal from 'react-onesignal';

const Layout = ({ className = '', children, title = '', description = '' }) => {
  const { siteName, siteUrl, siteTitle, siteDescription } = useSiteMetadata();
  const oggImage = `${siteUrl}${require(`../../images/ogg-image.png`).default}`;
  // const [hydrated, setHydrated] = useState(false);
  // useEffect(() => {
  //   setHydrated(true);
  // }, []);
  useEffect(() => {
    if(process.env.NODE_ENV !== 'development') {
      OneSignal.init({
        appId: process.env.GATSBY_ONE_SIGNAL_APP_ID,
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${title || siteTitle} | ${siteName}`}</title>
        <meta name="description" content={description || siteDescription} />
        <meta property="og:title" content={`${title || siteTitle} | ${siteName}`} />
        <meta property="og:description" content={description || siteDescription} />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:image" content={oggImage} />
        <meta property="og:image:alt" content={siteName} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={siteName} />
      </Helmet>
      {/*{hydrated && (*/}
        <>
          <div className={`page ${className}`} id="page">
            <Header />
            {children}
            <Footer />
          </div>
          <ScrollTopTopButton />
        </>
      {/*)}*/}
    </>
  );
};

export default Layout;
