exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-casino-games-games-yaml-slug-js": () => import("./../../../src/pages/casino-games/{GamesYaml.slug}.js" /* webpackChunkName: "component---src-pages-casino-games-games-yaml-slug-js" */),
  "component---src-pages-casino-games-index-js": () => import("./../../../src/pages/casino-games/index.js" /* webpackChunkName: "component---src-pages-casino-games-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-crypto-casinos-casinos-yaml-slug-js": () => import("./../../../src/pages/crypto-casinos/{CasinosYaml.slug}.js" /* webpackChunkName: "component---src-pages-crypto-casinos-casinos-yaml-slug-js" */),
  "component---src-pages-crypto-casinos-index-js": () => import("./../../../src/pages/crypto-casinos/index.js" /* webpackChunkName: "component---src-pages-crypto-casinos-index-js" */),
  "component---src-pages-cryptocurrencies-cryptocurrencies-yaml-slug-js": () => import("./../../../src/pages/cryptocurrencies/{CryptocurrenciesYaml.slug}.js" /* webpackChunkName: "component---src-pages-cryptocurrencies-cryptocurrencies-yaml-slug-js" */),
  "component---src-pages-cryptocurrencies-index-js": () => import("./../../../src/pages/cryptocurrencies/index.js" /* webpackChunkName: "component---src-pages-cryptocurrencies-index-js" */),
  "component---src-pages-exchanges-js": () => import("./../../../src/pages/exchanges.js" /* webpackChunkName: "component---src-pages-exchanges-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

